import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const SEO = ({ 
  title, 
  description,
  robotsContent = "follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large",
  canonicalUrl = null,
  // Additional meta properties
  language = "English",
  author = "Edulley",
  revisitAfter = "1 days",
  // Open Graph properties
  ogType = "website",
  ogUrl = null,
  ogTitle = null,
  ogDescription = null,
  // Optional content type
  contentType = "text/html; charset=utf-8"
}) => {
  const location = useLocation();
  const baseUrl = 'https://edulley.com';
  const canonical = canonicalUrl || `${baseUrl}${location.pathname}`;

  return (
    <Helmet>
      {/* Basic meta tags */}
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      <meta name="robots" content={robotsContent} />
      
      {/* Technical meta tags */}
      <meta httpEquiv="Content-Type" content={contentType} />
      <meta name="language" content={language} />
      <meta name="revisit-after" content={revisitAfter} />
      <meta name="author" content={author} />

      {/* Open Graph tags */}
      <meta property="og:type" content={ogType} />
      <meta property="og:url" content={ogUrl || canonical} />
      <meta property="og:title" content={ogTitle || title} />
      <meta property="og:description" content={ogDescription || description} />

      {/* Canonical URL */}
      <link rel="canonical" href={canonical} />
    </Helmet>
  );
};

export default SEO;