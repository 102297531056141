import React from "react";
import Universties from "../components/Home/Universties";
import SEO from "../components/SEO";

const UniversitesPage = () => {
  return (
    <div>
      <SEO 
 title="Top International Universities for Students | Foreign Institutions"
 description="Discover top universities for international students at Edulley. Explore foreign institutions that offer quality education and diverse cultural experiences."
 canonicalUrl="https://edulley.com/institutions"
/>
      <Universties />
    </div>
  );
};

export default UniversitesPage;
