import React from "react";
import Search from "../components/Home/Search";
import SEO from "../components/SEO";

const Courses = () => {
  return (
    <div className="min_height">
 <SEO 
  title="Certification, Skill Development & Professional Courses | Edulley"
  description="Discover Edulley's diverse selection of certification and skill development courses. Elevate your professional journey and achieve success with us."
  canonicalUrl="https://edulley.com/courses"
  ogUrl="https://edulley.com/courses"
  // The other og:tags will automatically use the title and description if not specified
/>
      <Search />
    </div>
  );
};

export default Courses;